import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import Slide from "components/features/TwoColWithImage.js";
import QuestionSlide from "components/faqs/ExpandingText.js";

import Image1 from "../../images/pages/hmc/1.jpg";
import Image2 from "../../images/pages/hmc/2.jpg";
import Image3 from "../../images/pages/hmc/3.jpg";
import Image4 from "../../images/pages/hmc/4.jpg";

const ButtonContainer = tw.div`flex flex-col items-center pb-24`;
const PrimaryButton = styled(PrimaryButtonBase)(
  tw`mt-4 py-3 md:mt-4 text-sm inline-block md:mx-4 w-48`
);

export default () => {
  return (
    <>
      <QuestionSlide
        imageSrc={Image1}
        subheading=""
        heading="HMC Projects"
        description=""
        faqs={[
          {
            question: "Despre HMC Projects",
            answer: (
              <p>
                Înființată în <strong>1992</strong>, HMC Projects este o{" "}
                <strong>organizație caritabilă</strong> care selectează și
                încadrează tineri talentați din medii vulnerabile, din fostele
                țări comuniste din <strong>Europa Centrală și de Est</strong>,
                în programe de burse, astfel încât aceștia să poată să studieze
                timp de doi ani în școlile internate din Marea Britanie. Din
                1992, peste 2000 de elevi inteligenți și aventuroși au câștigat
                burse prin intermediul HMC Projects, pentru a studia în școlile
                britanice. Alumnii acestor programe constituie, astăzi, o{" "}
                <strong>elită modernă, internațională și supranațională</strong>
                , sunt o inspirație pentru tineri și amintesc societății de
                virtuțile onorabile educate în spiritul britanic și beneficiile
                ce rezidă din{" "}
                <strong>colaborarea și cooperarea la nivel european</strong>.
              </p>
            ),
          },
          {
            question: "Beneficiari",
            answer: (
              <p>
                HMC Projects selectează în prezent elevi din{" "}
                <strong>15 țări</strong>, și anume: Armenia, Bosnia și
                Herțegovina, Bulgaria, Croația, Republica Cehă, Estonia,
                Georgia, Letonia, <strong>Moldova</strong>, Muntenegru,
                Macedonia de Nord, România, Serbia, Slovacia și Ucraina.
              </p>
            ),
          },
        ]}
      />
      <QuestionSlide
        imageSrc={Image2}
        subheading=""
        heading=""
        description=""
        textOnLeft={false}
        faqs={[
          {
            question: "Misiunea",
            answer: (
              <p>
                Scopul programului de burse este de a încuraja{" "}
                <strong>
                  legături de cooperare și prietenie între tinerii din toată
                  Europa
                </strong>{" "}
                și de a oferi elevilor din fostele țări comuniste din Europa
                Centrală și de Est o experiență bogată și fructuoasă în unele
                dintre cele mai bune școli din lume.
              </p>
            ),
          },
          {
            question: "Angajamentele HMC Projects",
            answer: (
              <>
                <p>
                  Elevii selectați de HMC Projects nu numai că vor beneficia de
                  o bună <strong>instruire academică</strong> și de{" "}
                  <strong>performanțe înalte</strong>, dar își vor confirma că
                  sunt <strong>adaptabili social</strong>, pregătiți să se
                  încadreze într-o școală din Marea Britanie și să aducă, în
                  cadrul acesteia, o contribuție majoră. Echipele de selecție
                  cuprind în mare parte șefi în retragere și foste cadre
                  superioare în cadrul școlilor independente din Marea Britanie.
                </p>
              </>
            ),
          },
          {
            question: "Perspective de viitor",
            answer: (
              <p>
                La finalizarea celor doi ani într-una dintre școlile
                prestigioase care oferă burse prin HMC Projects, elevii primesc,
                prin intermediul examenelor naționale,{" "}
                <strong>
                  calificări ce le vor asigura inserția în universități din
                  Marea Britanie
                </strong>{" "}
                și alte instituții de învățământ de top din străinătate. Unele
                universități din afara Marii Britanii pot solicita recunoașterea
                calificărilor academice obținute. Recent, absolvenții HMC
                Projects au continuat să studieze la universități de top din
                Olanda, Germania, Franța, Italia, SUA, Canada sau din țările lor
                de origine, câștigând deseori{" "}
                <strong>oferte de studiu și burse generoase</strong>.
              </p>
            ),
          },
        ]}
      />
      <Slide
        subheading=""
        heading="De ce HMC Projects?"
        description={
          <>
            <p className="pb-4">
              Elevii care studiază la una dintre școlile independente de
              prestigiu din Marea Britanie timp de doi ani, prin intermediul
              unei burse HMC Projects, vor experimenta un{" "}
              <strong>stil diferit de educație</strong> care:
            </p>
            <ul className="text-left list-disc pl-5">
              <li>
                Oferă spre alegere o <strong>gamă largă de subiecte</strong>;
              </li>
              <li>
                Solicită elevilor să studieze{" "}
                <strong>mai puține discipline</strong>, dar în profunzime,
                comparativ cu sistemul de educație din țara de origine;
              </li>
              <li>
                Încurajează elevul să devină mult{" "}
                <strong>mai independent</strong> în procesul de învățare;
              </li>
            </ul>
          </>
        }
        imageSrc={Image3}
      />
      <Slide
        subheading=""
        heading=""
        description={
          <>
            <ul className="text-left list-disc pl-5">
              <li>
                Va reprezenta o <strong>tranziție academică eficientă</strong>{" "}
                către cursurile universitare moderne;
              </li>
              <li>
                Încurajează <strong>gândirea critică</strong> și ajută la
                dezvoltarea unei <strong>minți curioase</strong>;
              </li>
              <li>
                Încurajează <strong>interacțiunea socială</strong> ce susține
                contribuția personală la viața comunitară;
              </li>
              <li>
                Permite studenților să experimenteze{" "}
                <strong>noi hobby-uri</strong> sau să le dezvolte pe cele
                existente, în domenii precum: muzica, drama, sportul și suportul
                pentru comunitatea locală;
              </li>
              <li>
                Permite elevilor să dezvolte{" "}
                <strong>capacitatea de formare</strong> pe tot parcursul vieții.
              </li>
            </ul>
          </>
        }
        imageSrc={Image4}
      />
      <ButtonContainer>
        <PrimaryButton
          as="a"
          href="/hmc-assist/hmc-offer"
          className="text-center"
        >
          Oferta 2023-2024
        </PrimaryButton>
      </ButtonContainer>
    </>
  );
};
