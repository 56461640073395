import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import { SectionHeading as Heading } from "components/misc/Headings.js";
import {
  PrimaryButton as PrimaryButtonBase,
  SecondaryButton as SecondaryButtonBase,
} from "components/misc/Buttons.js";
import HMCLogo from "images/hmc-logo.png";
import ASSISTLogo from "images/assist-logo.png";
import { APPLICATION_LINK } from "../../constants";

const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/2`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img``;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose`;
const Description = tw.p`mt-4 text-sm lg:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-4 md:mt-4 text-sm inline-block mx-auto md:mx-0 w-48`,
  props.buttonRounded && tw`rounded-full`,
]);

const SecondaryButton = styled(SecondaryButtonBase)((props) => [
  tw`mt-4 md:mt-4 text-sm inline-block mx-auto md:mx-0 w-48`,
  props.buttonRounded && tw`rounded-full`,
]);

const MobileButton = styled(PrimaryButton)(tw`inline-block md:hidden`);

const DesktopButton = styled(PrimaryButton)(tw`hidden md:inline-block`);

export default ({
  heading,
  testimonials = [
    {
      imageComponent: ASSISTLogo,
      imageUrl: "https://www.assistscholars.org",
      description:
        "ASSIST, organizație non-profit americană ce include elevi talentați în școli private din Statele Unite. Peste 50 de ani de experiență, mii de  elevi erudiți, 52 de țări și 68 de școli alcătuiesc o rețea comună a liderilor de mâine.",
      primaryButtonUrl: "/hmc-assist/assist-offer",
      secondaryButtonUrl: "/hmc-assist/assist",
      buttonLabel: "Oferta '24-'25",
    },
    {
      imageComponent: HMCLogo,
      imageUrl: "https://www.hmc.org.uk/about-hmc/projects/",
      description:
        "HMC Projects se preocupă de încadrarea elevilor ce provin din țările Europei Centrale și de Est în școli partenere din Marea Britanie, alese cu maximă precizie și rigoare, pentru a asigura o instruire academică memorabilă și de calitate.",
      primaryButtonUrl: "/hmc-assist/hmc-offer",
      secondaryButtonUrl: "/hmc-assist/hmc",
      buttonLabel: "Oferta '24-'26",
    },
  ],
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <Heading>{heading}</Heading>
        {APPLICATION_LINK && (
        <div className="text-center">
          <MobileButton
            as="a"
            href={APPLICATION_LINK}
            className="text-center"
            target="_blank"
          >
            Aplică acum
          </MobileButton>
        </div>)}
        <Description className="pl-10 pr-10">
          <p className="pb-4 pt-6 text-center">
            Ai visat vreodată să studiezi într-una din{" "}
            <strong>școlile de top</strong> din țări cu o valoroasă expertiză în
            domeniul educației, precum <strong>SUA</strong>,{" "}
            <strong>Marea Britanie</strong> etc.? Ești în căutare de
            oportunități de a studia, de a experimenta lumea, de a-ți îmbogăți bagajul de cunoștințe generale
            , de a-ți satisface curiozitatea și de
            a-ți explora talentele, în cadrul unui schimb intercultural, în afara
            țării?
          </p>
          <p className="text-center">
            <strong>ACUM</strong> e momentul tău! <strong>APLICĂ</strong> pentru
            o bursă de studiu internațională, în cadrul programelor ASSIST și HMCP,
            pentru anii <strong>'24&#8209;'25</strong>/<strong>'24&#8209;'26</strong>! Completează
            formularul și înscrie-te la concurs.
          </p>
        </Description>
        {APPLICATION_LINK && (
        <div className="text-center">
          <DesktopButton
            as="a"
            href={APPLICATION_LINK}
            className="text-center"
            target="_blank"
          >
            Aplică acum
          </DesktopButton>
        </div>)}
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <div className="content-center h-30">
                  <a
                    href={testimonial.imageUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      className="profile-logo"
                      src={testimonial.imageComponent}
                    />
                  </a>
                </div>
                <Quote>{testimonial.description}</Quote>
                <PrimaryButton as="a" href={testimonial.primaryButtonUrl}>
                  {testimonial.buttonLabel}
                </PrimaryButton>
                <SecondaryButton as="a" href={testimonial.secondaryButtonUrl}>
                  Află mai multe
                </SecondaryButton>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>
    </Container>
  );
};
