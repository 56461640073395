import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { APPLICATION_LINK } from "../../constants";

import { SectionHeading as Heading } from "components/misc/Headings.js";
import StepSlide from "components/features/ImageWithSteps.js";
import QuestionSlide from "components/faqs/ExpandingText.js";

import InformationDavis from "../../assets/Information Davis.pdf";
import InformationASSIST from "../../assets/Information ASSIST.pdf";
import { ReactComponent as File } from "feather-icons/dist/icons/file-text.svg";
import Image1 from "../../images/pages/assist/4.jpg";
import Image2 from "../../images/pages/assist/5.jpg";

const Description = tw.p`mt-4 text-sm lg:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const Container = tw.div`flex flex-col justify-between max-w-screen-xl mx-auto py-10 md:py-24 items-center`;
const ButtonContainer = tw.div`flex flex-col lg:items-end items-center`;
const PrimaryButton = styled(PrimaryButtonBase)(
  tw`mt-4 py-3 md:mt-4 text-sm inline-block md:mx-4 w-48`
);

export default () => {
  return (
    <>
      <QuestionSlide
        imageSrc={Image1}
        subheading=""
        heading="3 tipuri de burse ASSIST pentru elevi"
        description=""
        buttonSrc={APPLICATION_LINK}
        faqs={[
          {
            question:
              "1. Bursa Sanderson (complet finanțată)",
            answer: (
              <>
                <p>
                  Acoperă integral costurile pentru școlarizare și cazare în
                  campusul unei școli private americane sau pentru școlarizare
                  și cazare, sub tutela familiei-gazdă americane. ASSIST percepe
                  o taxă administrativă unică, de $3.300, din partea familiei
                  candidatului câștigător.
                </p>
              </>
            ),
          },
          {
            question:
              "2. Bursa Lindfors (cu taxă de școlarizare redusă)",
            answer: (
              <>
                <p>
                  Acoperă parțial costurile pentru școlarizarea și cazarea
                  elevilor. Familia elevului va plăti aproximativ jumătate
                  din costul studiilor - aproximativ $35.500. În cadrul bursei
                  Lindfors, taxa administrativă nu este percepută.
                </p>
              </>
            ),
          },
          {
            question: "3. Programul de școlarizare DAVIS",
            answer: (
              <>
                <p>
                  Oferă posibilitatea de a studia timp de doi sau trei ani la
                  una dintre cele patru școli din cadrul programului
                  internațional DAVIS. Elevii vor primi, la sfârșitul
                  programului, o diplomă de absolvire americană. Procesul de
                  selecție pentru bursele DAVIS este deosebit de competitiv,
                  deoarece, în mod normal, se acceptă 3-5 candidați din cele 52
                  de țări, în fiecare an.
                </p>
              </>
            ),
          },
        ]}
      />
      <StepSlide
        subheading=""
        heading={<>Criterii de eligibilitate</>}
        textOnLeft={false}
        imageSrc={Image2}
        steps={[
          {
            heading: "Clasa",
            description:
              "Înmatriculat(ă) în clasele a IX-a, a X-a și a XI-a, în anul academic 2023-2024.",
          },
          {
            heading: "Competențele lingvistice",
            description:
              "Cunoaștere bună a limbii engleze, scris, citit și vorbit",
          },
          {
            heading: "Performanța școlară",
            description:
              "Media generală mai mare de 8.5, la finalul ultimilor doi ani academici.",
          },
        ]}
      />
      <Container>
        <Heading>Termeni și condiții generale</Heading>
        <Description className="pl-5 pr-5">
          <>
            <ol className="list-decimal">
              <li className="pb-4">
                Bursa de studiu se oferă pe o perioadă de zece luni, după care
                elevul se întoarce în țara de origine și este re-încadrat în
                sistemul de învățământ al acesteia. Elevul poate reveni în SUA
                doar după absolvirea liceului în R. Moldova, pentru a-și
                continua studiile.
              </li>
              <li className="pb-4">
                Elevul trebuie să rămână în SUA timp de zece luni, petrecând
                vacanțele școlare și zilele naționale cu familia-gazdă
                americană. Vizitele părinților sunt descurajate până în
                primăvară, pentru a asigura o mai simplă acomodare culturală.
              </li>
              <li className="pb-4">
                Candidatul nu-și poate exprima preferințele vis-a-vis de o
                anumită școală, regiune geografică sau tip de școală (de zi sau
                internat). Decizia finală de acceptare îi revine școlii, în
                colaborare cu ASSIST.
              </li>
              <li className="pb-4">
                ASSIST percepe o taxă administrativă din partea familiei
                candidatului câștigător, publicată anual înainte de încheierea
                acordului de aderare la program. Aceasta acoperă costurile
                administrative și operaționale ale ASSIST, precum costul
                orientării în America, costul transportului către familia gazdă
                sau școală.
              </li>
              <li className="pb-4">
                Candidatul câștigător al unei burse complete ASSIST este scutit
                de taxele de școlarizare, care pot ajunge până la $72.000.
              </li>
              <li className="pb-4">
                Elevii care provin din familii cu mijloace financiare modeste
                sunt susținuți de către donatori americani. O parte sau toate
                costurile asociate participării la programul ASSIST sunt
                suportate de acești sponsori.
              </li>
            </ol>
            <a
              href={InformationASSIST}
              target="_blank"
              rel="noreferrer"
              className="flex items-center pb-4"
            >
              <File className="mr-2" />
              <p>Informații generale despre programul ASSIST</p>
            </a>
            <a
              href={InformationDavis}
              target="_blank"
              rel="noreferrer"
              className="flex items-center pb-4"
            >
              <File className="mr-2" />
              <p>Informații generale despre programul DAVIS</p>
            </a>
          </>
        </Description>
        {APPLICATION_LINK && (
        <ButtonContainer>
          <PrimaryButton
            as="a"
            href={APPLICATION_LINK}
            className="text-center"
            target="_blank"
          >
            Aplică acum
          </PrimaryButton>
        </ButtonContainer>)}
      </Container>
    </>
  );
};
