import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { APPLICATION_LINK } from "../../constants";

import { SectionHeading as Heading } from "components/misc/Headings.js";
import StepSlide from "components/features/ImageWithSteps.js";
import QuestionSlide from "components/faqs/ExpandingText.js";

import InformationParents from "../../assets/Information for parents of applicants.pdf";
import InformationAplicants from "../../assets/Information for applicants.pdf";
import { ReactComponent as File } from "feather-icons/dist/icons/file-text.svg";
import Image1 from "../../images/pages/hmc/5.jpg";
import Image2 from "../../images/pages/hmc/6.jpg";

const Description = tw.p`mt-4 text-sm lg:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const Container = tw.div`flex flex-col justify-between max-w-screen-xl mx-auto py-10 md:py-24 items-center`;
const ButtonContainer = tw.div`flex flex-col items-center`;
const PrimaryButton = styled(PrimaryButtonBase)(
  tw`mt-4 py-3 md:mt-4 text-sm inline-block md:mx-4 w-48`
);

export default () => {
  return (
    <>
      <QuestionSlide
        imageSrc={Image1}
        subheading=""
        heading="Două tipuri de burse HMCP pentru elevi"
        description=""
        buttonSrc={APPLICATION_LINK}
        faqs={[
          {
            question:
              "1. Bursă de studiu complet finanțată (Full Scholarship Programme)",
            answer: (
              <>
                <p>
                  Acoperă <strong>integral</strong> costul școlarizării,
                  cazarea, mâncarea și alte cerințe de bază. Pentru a fi
                  eligibil, venitul părinților candidatului în 2023 nu trebuie
                  să depășească £30.000. HMCP percepe o{" "}
                  <strong>taxă administrativă nerambursabilă</strong> de £1.000
                  de la candidații câștigători, plătită cel târziu până în data
                  de 1 iulie 2024. La aceasta se adaugă costul vizei și
                  asigurarea de sănătate - aproximativ £2.360, pentru cei doi
                  ani de studii.
                </p>
              </>
            ),
          },
          {
            question:
              "2. Bursă de studiu cu taxă de școlarizare redusă (Reduced Fee Scholarship Programme)",
            answer: (
              <>
                <p>
                  Destinată candidaților ai căror părinți au venituri între
                  £30.000 și £80.000. Taxele percepute variază între £6.000 și
                  £11.000 pe an, echivalentul a 20-25% din costul taxei de
                  studiu. Pentru bursele de studiu parțiale, HMCP{" "}
                  <strong>nu percepe nicio taxă administrativă</strong>.
                </p>
              </>
            ),
          },
        ]}
      />
      <StepSlide
        subheading=""
        heading={<>Criterii de eligibilitate</>}
        textOnLeft={false}
        imageSrc={Image2}
        steps={[
          {
            heading: "Vârsta",
            description:
              "Născut între 01.02.2007 și 01.09.2008 (16 ani-17 ani și 6 luni, la 01.09.2024)",
          },
          {
            heading: "Clasa",
            description:
              "Înmatriculat(ă) în clasa a X-a, liceu, în anul academic 2023-2024",
          },
          {
            heading: "Aspectele financiare",
            description: (
              <ul className="text-left list-disc pl-5">
                <li>
                  pentru bursă de studii completă, veniturile părinților în anul
                  2023 nu trebuie să depășească £30.000, fapt atestat printr-o
                  declarație;
                </li>
                <li>
                  pentru bursă cu taxă de școlarizare redusă, veniturile
                  părinților în 2023 se încadrează între £30.000 și £80.000;
                </li>
                <li>
                  dacă venitul familiei depășește £80.000, atunci candidatul nu
                  este eligibil în cadrul programului.
                </li>
              </ul>
            ),
          },
          {
            heading: "Statutul",
            description:
              "Candidatul nu urmează în prezent o altă școală din Marea Britanie și nu a fost deja acceptat să o facă pentru anul academic 2024-2025",
          },
          {
            heading: "Competențele lingvistice",
            description:
              "Cunoaștere bună a limbii engleze, scris, citit și vorbit",
          },
          {
            heading: "Performanța școlară",
            description:
              "Media generală mai mare de 8.5, la sfârșitul clasei a IX-a",
          },
        ]}
      />
      <Container>
        <Heading>Termeni și condiții generale</Heading>
        <Description className="pl-5 pr-5">
          <ol className="list-decimal">
            <li className="pb-4">
              Bursa prevede șederea de lungă durată în Marea Britanie, pe toată
              perioada studiilor. Astfel, elevii se vor afla departe de casă, cu
              excepția vacanțelor școlare prevăzute în program. De asemenea,
              toți elevii internaționali, inclusiv cei ce dețin pașaport UE, vor
              avea nevoie de viză.
            </li>
            <li className="pb-4">
              Elevii vor avea asigurate locuri de cazare în campusul școlii,
              unde vor fi supravegheați de membrii personalului. Căminele sunt
              numai de băieți sau numai de fete.
            </li>
            <li className="pb-4">
              Majoritatea elevilor vor împărți o cameră cu un alt elev, unde vor
              dormi, vor studia și își vor păstra lucrurile personale, hainele,
              cărțile și alte obiecte. Toate căminele au reguli specifice,
              menite să protejeze nevoile și interesele fiecăruia dintre membrii
              lor: să respecte programul, să păstreze o conduită decentă, să nu
              consume alcool, țigări sau droguri etc.
            </li>
            <li className="pb-4">
              Pe lângă curricula școlară, elevii vor beneficia de o gamă largă
              de alte activități complementare materiilor academice, prin care
              își pot dezvolta talentele și dobândi noi competențe (sport, arte,
              muzică etc.).
            </li>
            <li className="pb-4">
              Bursa se oferă pe o durată de doi ani, elevii fiind înmatriculați
              în anul al doilea de studii în condiții identice sau similare.
              Școlile își rezervă totuși dreptul de a retrage o bursă unui elev,
              dacă acesta nu reușește să îndeplinească standardele înalte
              așteptate, atât din punct de vedere academic, cât și social.
            </li>
            <li className="pb-4">
              Anul școlar din Marea Britanie este împărțit în trimestre, cu
              vacanțe de Crăciun, de Paște și de vară. Nu este posibil ca elevii
              să rămână la școală în perioadele de vacanță. În ceea ce privește
              călătoriile către și de la școală, acestea pot implica până la
              șase zboruri dus-întors. Este recomandat ca părinții să ia în
              calcul costul potențial al acestor călătorii internaționale.
            </li>
          </ol>
          <a
            href={InformationAplicants}
            target="_blank"
            rel="noreferrer"
            className="flex items-center pb-4"
          >
            <File className="mr-2" />
            <p>Informații generale pentru aplicanți</p>
          </a>
          <a
            href={InformationParents}
            target="_blank"
            rel="noreferrer"
            className="flex items-center"
          >
            <File className="mr-2" />
            <p>Informații generale pentru părinți</p>
          </a>
        </Description>
        {APPLICATION_LINK && (
        <ButtonContainer>
          <PrimaryButton
            as="a"
            href={APPLICATION_LINK}
            className="text-center"
            target="_blank"
          >
            Aplică acum
          </PrimaryButton>
        </ButtonContainer>)}
      </Container>
    </>
  );
};
