import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import QuestionSlide from "components/faqs/ExpandingText.js";
import Slide from "components/features/TwoColWithImage.js";

import Image1 from "../../images/pages/assist/1.jpg";
import Image2 from "../../images/pages/assist/2.jpg";
import Image3 from "../../images/pages/assist/3.jpg";

const ButtonContainer = tw.div`flex flex-col items-center pb-24`;
const PrimaryButton = styled(PrimaryButtonBase)(
  tw`mt-4 py-3 md:mt-4 text-sm inline-block md:mx-4 w-48`
);

export default () => {
  return (
    <>
      <QuestionSlide
        imageSrc={Image1}
        subheading=""
        heading="ASSIST"
        description=""
        faqs={[
          {
            question: "Despre ASSIST",
            answer: (
              <>
                <p className="pb-4">
                  ASSIST este o organizație non-profit americană ce creează{" "}
                  <strong>adevărate rețele complexe și conexe</strong> între
                  elevi talentați, curajoși și pasionați de lingvistică/limbi
                  străine și <strong>școli private partenere</strong> din
                  Statele Unite.
                </p>
                <p>
                  Pe timpul șederii de un an, ASSIST oferă beneficiarilor marea
                  oportunitate să devină ambasadori ai patrimoniului cultural și
                  valoric al țării din care provin, dar și să obțină educație
                  performantă și calitativă gratuit, participând activ la
                  cursuri și activități extracurriculare în cadrul școlilor
                  private americane.
                </p>
              </>
            ),
          },
          {
            question: "5 decenii pe făgașul Învățământului",
            answer: (
              <p>
                Din 1969 și până în prezent, ASSIST a dezvoltat o rețea globală
                impresionantă, stabilind noduri de legătură între{" "}
                <strong>5.656 de elevi</strong> erudiți din{" "}
                <strong>52 de țări</strong> și{" "}
                <strong>68 de școli și centre educaționale secundare</strong>{" "}
                private americane.
              </p>
            ),
          },
        ]}
      />
      <QuestionSlide
        imageSrc={Image2}
        subheading=""
        heading=""
        description=""
        textOnLeft={false}
        faqs={[
          {
            question: "Misiune",
            answer: (
              <p>
                Misiunea organizației este de a genera elevilor ciclului liceal
                oportunități de școlarizare în centre educaționale americane de
                prestigiu. Un an de studii în SUA oferă beneficiarilor
                experiențe academice remarcabile, dar și generează un impact
                extraordinar la nivel de personalitate. ASSIST își propune să
                reunească liderii de mâine într-o{" "}
                <strong>
                  rețea a colaborării, cooperării și a dialogului internațional
                </strong>
                , prin promovarea înțelegerii, toleranței și acceptării, pentru
                a pune capăt dezbinării și urii pe criterii rasiale, religioase
                etc.
              </p>
            ),
          },
          {
            question: "Angajament",
            answer: (
              <p>
                Reprezentații ASSIST, anual, își concentrează toate eforturile
                pentru a identifica, a susține și a încadra în instituții de
                învățământ secundar tineri erudiți și talentați din întreaga
                lume, creând astfel{" "}
                <strong>un cerc al liderilor mondiali de mâine</strong> - cu{" "}
                <strong>pasiune</strong>, <strong>viziune</strong> și{" "}
                <strong>conexiuni interculturale profunde</strong>.
              </p>
            ),
          },
          {
            question: "Ambasadori ASSIST",
            answer: (
              <p>
                Fiecare participant, fără excepție, excelează din punct de
                vedere <strong>academic</strong>, <strong>personal</strong> și{" "}
                <strong>cultural</strong> pe timpul șederii sale în SUA,
                contribuie la cel puțin un program extracurricular și își
                exercită cu mândrie calitatea de{" "}
                <strong>ambasador al țării și culturii din care provine</strong>
                .
              </p>
            ),
          },
        ]}
      />
      <Slide
        subheading=""
        heading="De ce ASSIST?"
        description={
          <>
            <p className="pb-4">
              Un an de program în SUA îți poate asigura numeroase beneficii:
            </p>
            <ul className="list-disc pl-5 text-left">
              <li>
                Vei învăța limba engleză într-o comunitate americană vibrantă de{" "}
                <strong>vorbitori nativi</strong>;
              </li>
              <li>
                Vei exersa propriile <strong>abilități de leadership</strong>,
                prin provocările schimbului cultural;
              </li>
              <li>
                Îți vei testa constant <strong>performanța lingvistică</strong>,
                pe tot parcursul anului în străinătate;
              </li>
              <li>
                Vei întâlni alți viitori lideri și vei lega prietenii pe viață;
              </li>
              <li>
                Vei primi <strong>asistență 24/7</strong> de la personalul
                ASSIST pe timpul șederii de un an;
              </li>
              <li>
                Vei deveni membru al unei rețele globale de 5.656 de absolvenți
                din 52 de țări;
              </li>
              <li>
                Vei crește mai independent la un liceu privat atent selectat, în
                care te vei simți ca acasă.
              </li>
            </ul>
          </>
        }
        imageSrc={Image3}
      />
      <ButtonContainer>
        <PrimaryButton
          as="a"
          href="/hmc-assist/assist-offer"
          className="text-center"
        >
          Oferta 2023-2024
        </PrimaryButton>
      </ButtonContainer>
    </>
  );
};
